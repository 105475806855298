import request from "@/utils/request";

// 查询订单编号
export function AfterList() {
  return request.get("/order/after/sale/query/order/number");
}

// 根据订单编号查询详情
export function AfterDetail(orderNumber) {
  return request.get(`/order/order/query/short/message/${orderNumber}`);
}

// 提交
export function PostSmit(param) {
  return request.post("/order/after/sale/commit", param);
}

export function express(data) {
  return request.post("/order/after/sale/returned/express", data);
}

// 查询售后列表
export function OrderSaleList({
  pageNum,
  pageSize,
  param,
  status,
  reviewStatus,
}) {
  return request.get("/order/after/sale/query/list", {
    pageSize,
    param,
    status,
    reviewStatus,
    pageNum,
  });
}

// 查询我的详情
export function SaleAfterDetail(id) {
  return request.get(`/order/after/sale/query/detail/${id}`);
}

// 售后类型售后原因
export function AfterSaleReason() {
  return request.get("/order/after/sale/reason/list");
}

// 再次申请
export function AfterResubmit(afterSaleOrderNumber) {
  return request.get(
    `/order/after/sale/query/after/sale/order/${afterSaleOrderNumber}`
  );
}

// 删除图片
export function DeleteImg(fileName) {
  return request.delete(`/order/common/deleteFile/?fileName=${fileName}`);
}

// 删除图片2
export function DeleteImgs(param) {
  return request.delete("/order/after/sale/delete/file/path", param);
}
