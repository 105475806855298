<template>
  <div class="content">
    <p style="color: #fff">1</p>
    <div class="content-index"></div>
    <p class="text">暂无数据</p>
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
.content {
  width: 980px;
  height: 320px;
  background: #ffffff;
  .content-index {
    width: 323px;
    height: 200px;
    background-image: url("../../assets/images/noContent.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    margin-top: 22px;
  }
  .text {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin: auto;
    text-align: center;
    margin-top: 13px;
  }
}
</style>
